import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

import EarlyAccessLandingPage from "../components/early-access-landing-page"
// import campaignBuilderGIF from "../gifs/campaign-builder.gif"

const Heading = () => (
  <span>
    Early Access Special Offer
  </span>
)

// const Subheading = () => (
//   <span>
//     Send 2000+ postcards and get <span class="has-text-weight-semibold">free custom design </span> 
//     plus <span class="has-text-weight-semibold">20% off</span> with Early Access
//   </span>
// )

// const Media = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       campaignBuilderDesktopImage: file(relativePath: { eq: "campaign-builder-desktop.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 800) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `)

//   return (
//     <>
//       <img src={campaignBuilderGIF} alt="Campaign Builder" />
//       {/* <Img fluid={data.campaignBuilderDesktopImage.childImageSharp.fluid} /> */}
//     </>
//   )
// }

const EarlyAccessSpecialOfferPage = () => {

  return (
    <EarlyAccessLandingPage
      title="Early Access Special Offer"
      url="early-access-special-offer"
      Heading={Heading}
      // Subheading={Subheading}
      // Media={Media}
      // cta1="Let's Do It"
    />
)}

export default EarlyAccessSpecialOfferPage